import { LOCALE } from 'constants/i18n';

import { isLocale } from 'types/typeGuards';
import { useQuery } from 'utils/navigation';

export const useLocaleFromUrl = (): LOCALE | null => {
  const query = useQuery();
  const localeQueryParam =
    query.locale && typeof query.locale === 'string' ? query.locale.toLowerCase() : undefined;

  return localeQueryParam && isLocale(localeQueryParam) ? localeQueryParam : null;
};
