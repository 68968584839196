import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { modifyQuery } from 'utils/navigation';

import { useSelectedRole } from './useSelectedRole';

export const useChangeRoleFromUrl = () => {
  const { selectedRole, setSelectedRole } = useSelectedRole();
  const searchParams = useSearchParams();

  useEffect(() => {
    const role = searchParams.get('role');

    if (!role) {
      return;
    }

    if (role !== selectedRole) {
      setSelectedRole(role);
    }

    modifyQuery({
      role: undefined,
    });
  }, [searchParams, selectedRole, setSelectedRole]);
};
