import { useEffect } from 'react';

import { LOCALE } from 'constants/i18n';

import { modifyQuery, useModifyRoute } from 'utils/navigation';

import { useLocaleFromUrl } from './useLocaleFromUrl';

export const useChangeLocaleFromUrl = ({
  locale,
  routeLocalizations,
}: {
  locale: LOCALE;
  routeLocalizations: Record<LOCALE, string>;
}) => {
  const localeInUrl = useLocaleFromUrl();
  const modifyRoute = useModifyRoute();

  useEffect(() => {
    if (!localeInUrl) {
      return;
    }

    if (localeInUrl !== locale) {
      modifyRoute('replace', (prev) => ({
        ...prev,
        pathname: routeLocalizations[localeInUrl],
        search: { ...prev.search, locale: undefined },
      }));
    } else {
      modifyQuery({ locale: undefined });
    }
  }, [locale, localeInUrl, modifyRoute, routeLocalizations]);
};
