'use client';
import { ReactNode } from 'react';

import { ScreenReaderAnnouncementsProvider } from 'components/ui/ScreenReaderAnnouncements';

import { ModalHandler } from '../ModalHandler/ModalHandler';

export const PageRootComponents = ({ children }: { children: ReactNode }) => {
  return (
    <ScreenReaderAnnouncementsProvider>
      <ModalHandler>{children}</ModalHandler>
    </ScreenReaderAnnouncementsProvider>
  );
};
