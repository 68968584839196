'use client';

import { LOCALE } from 'constants/i18n';
import { useChangeLocaleFromUrl } from 'hooks/useChangeLocaleFromUrl';
import { useChangeRoleFromUrl } from 'hooks/useChangeRoleFromUrl';

export const PageHooks = ({
  locale,
  routeLocalizations,
}: {
  locale: LOCALE;
  routeLocalizations: Record<LOCALE, string>;
}) => {
  useChangeLocaleFromUrl({ locale, routeLocalizations });
  useChangeRoleFromUrl();
  return null;
};
