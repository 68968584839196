'use client';
import { ReactNode } from 'react';

import { Page, RawPageProps } from 'build/types';
import { AssetsProviderWrapper } from 'components/structural/AssetsProviderWrapper';
import { PageRootComponents } from 'components/structural/PageRootComponents/PageRootComponents';
import { PageHooks } from './PageHooks';

type Props = RawPageProps & { page: Page; children: ReactNode };

const PageProviders = ({ children, page, ...props }: Props) => {
  const { locale, routeLocalizations } = props;

  return (
    <>
      <PageHooks locale={locale} routeLocalizations={routeLocalizations} />
      <PageRootComponents>
        <AssetsProviderWrapper page={page}>{children}</AssetsProviderWrapper>
      </PageRootComponents>
    </>
  );
};

export default PageProviders;
