import { Asset } from 'contentful';

import { createProvider } from 'utils/createProvider';

type AssetKey = ContentfulWeb.TypeAssetWithKey['fields']['key'];

export type AssetsContext = Partial<Record<AssetKey, Asset>>;

const [useAssets, AssetsProvider] = createProvider<AssetsContext>('ModalProvider');

export { AssetsProvider, useAssets };
