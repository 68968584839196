import { ReactNode, useMemo } from 'react';

import { Page } from 'build/types';

import { AssetsContext, AssetsProvider } from 'contexts/assets';

export const AssetsProviderWrapper = ({ page, children }: { page: Page; children: ReactNode }) => {
  const assets = useMemo(
    () =>
      'assets' in page.fields && page.fields.assets
        ? page.fields.assets.reduce<AssetsContext>(
            (acc, asset) => (asset ? { ...acc, [asset.fields.key]: asset.fields.asset } : acc),
            {},
          )
        : {},
    [page.fields],
  );

  return <AssetsProvider value={assets}>{children}</AssetsProvider>;
};
